import * as React from "react"
class Greeting extends React.Component {
	state = {
	  hour: null,
	}
	
	componentDidMount() {
	  this.getHour()
	}
  
	getHour = () => {
	 const date = new Date();
	 const hour = date.getHours()
	 this.setState({
		hour
	 });
	}
  
	render(){
		if(this.state) {
			if (this.state.hour > 3 && this.state.hour < 12)
				return <span style={{color: '#FFCA28'}}>Good morning!</span>;
			else if (this.state.hour > 12 && this.state.hour < 18)
				return <span style={{color: '#3F51B5'}}>Good afternoon!</span>;
			else
				return <span style={{color: '#1A237E'}}>Good evening!</span>;
		} else {
			return <span>&nbsp;</span>
		}
	}
  
  }

  export default Greeting;