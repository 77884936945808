import * as React from "react"
import { Helmet } from "react-helmet"
import Greeting from '../components/Greeting.js'
import { Email } from "react-obfuscate-email";
import '../styles/global.css'

// styles
const pageStyles = {
  fontFamily: "Roboto, sans-serif, serif",
}

// data
const links = [
  {
    text: "LinkedIn",
    url: "https://www.linkedin.com/in/samuelhautcoeur/",
  },
]

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Samuel Hautcoeur</title>
      </Helmet>
      <title>Samuel Hautcoeur</title>
      <h1>
        <span><Greeting /></span><br />You have found <span class="d-nowrap">Samuel Hautcoeur</span>.
      </h1>
      <p>
        <strong>Chief Executive Maker of Awesome at <a href="https://ep4.com/">EP4 Corporation</a> &#9889; Black Belt WordPress Developer</strong>
        <br />Located in Quebec city, Canada.
      </p>
      <ul class="btn-list">
        {links.map(link => (
          <li key={link.url}>
            <span>
              <a
                class='btn'
                href={`${link.url}`}
              >
                {link.text}
              </a>
            </span>
          </li>
        ))}
        <li>
          <span>
            <Email className="btn" email="samuel.hautcoeur@ep4.com">Email</Email>
          </span>
        </li>
                  
      </ul>
    </main>
  )
}

export default IndexPage
